import React from "react";
import { Collapse, IconButton, useTheme } from "@mui/material";
import { HighlightOff, Search as SearchIcon } from "@mui/icons-material";
import { TransitionGroup } from "react-transition-group";
import { SearchIconWrapper } from "./SearchIconWrapper";
import { SearchField } from "./SearchField";
import { SearchInputBase } from "./SearchInputBase";

export interface SearchBarProps extends React.HTMLAttributes<HTMLDivElement> {
    query: string;
    onClose: () => void;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const SearchBar = (props: SearchBarProps) => {
    const theme = useTheme();
    const { query, onClose, onChange  } = props;
    return (<TransitionGroup style={{ display: "inline-flex", width: "100%" }}>
    <Collapse
      orientation={"horizontal"}
      classes={{ wrapperInner: "fullwidth" }}
      style={{ flexGrow: 1, width: "100%" }}
    >
      <SearchField>
        <SearchIconWrapper>
          <SearchIcon />
        </SearchIconWrapper>
        <SearchInputBase
          value={query}
          onChange={onChange}
          placeholder="Search…"
          inputProps={{ "aria-label": "search" }}
        />
      </SearchField>
    </Collapse>
    {query !== "" ? (
      <Collapse orientation={"horizontal"}>
        <IconButton
          onClick={() => {
            onClose();
          }}
          sx={{ ml: 1, pl: 0, fontSize: 24 }}
        >
          <HighlightOff sx={{ color: theme.palette.common.white }} />
        </IconButton>
      </Collapse>
    ) : null}
  </TransitionGroup>)
}

export default SearchBar;