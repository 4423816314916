import React, { useState, ReactNode } from "react";
import SnackabraContext from "contexts/SnackabraContext";
import SongsContext, { Song } from "./SongsContext";
import * as _ from "lodash";
import WalletContext from "contexts/WalletContext";
import { ChannelStoreType } from "react384";


interface IHistoryContextInterface {
  history: Song[],
  readyFlag: React.MutableRefObject<Promise<boolean> | undefined>,
  addSongToHistory: (song: Song) => Promise<IHistoryMessageObject>
  importMetadata: (song: IHistoryMessageObject) => Promise<void>
}

interface IHistoryMessageObject {
  _id: string;
  createdAt: string;
  coverId: string;
  songId: string;
  version: string
  messageType?: string
}

interface IHistorySongObject extends Song {
  createdAt: string
}


const HistoryContext = React.createContext<IHistoryContextInterface>({
  history: [],
  readyFlag: { current: undefined },
  addSongToHistory: () => new Promise(() => { }),
  importMetadata: () => new Promise(() => { })
});


export const HistoryProvider = ({ children }: { children: ReactNode }) => {
  const history_version = 'history_v7'
  const readyResolver = React.useRef<any>(null)
  const readyFlag = React.useRef<Promise<boolean>>()
  const SBContext = React.useContext(SnackabraContext)
  const walletContext = React.useContext(WalletContext)
  const songsContext = React.useContext(SongsContext)
  const [history, setHistory] = useState<IHistorySongObject[]>([]);
  const [channel, setChannel] = useState<ChannelStoreType | undefined>(undefined)

  React.useEffect(() => {
    readyFlag.current = new Promise((resolve) => {
      readyResolver.current = resolve
    })
  }, [])

  React.useEffect(() => {
    if (SBContext && SBContext.channels[walletContext.id as string]) {
      setChannel(SBContext.channels[walletContext.id as string] as ChannelStoreType)
      readyResolver.current(true)
    }
  }, [SBContext, walletContext.id])

  const dateToTimestamp = (date: Date) => {
    return date.getTime()
  }

  const importMetadata = async (message: IHistoryMessageObject) => {
    await songsContext.readyFlag.current
    if (message.version !== history_version) {
      console.error('wrong history version')
      return;
    }
    const _song = await songsContext.getSong(message.songId) as IHistorySongObject
    if (_song){
      _song.createdAt = message.createdAt
    setHistory((prevHistory) => {
      let _prevHistory = prevHistory
      _prevHistory.push(_song)
      _prevHistory = _prevHistory.sort((a, b) => {
        return dateToTimestamp(new Date(b.createdAt)) - dateToTimestamp(new Date(a.createdAt))
      })

      return _.uniqBy(_prevHistory.splice(0, 10), '_id')
    })
  }

}

const addSongToHistory = (song: Song): Promise<IHistoryMessageObject> => {
  return new Promise(async (resolve, reject) => {
    await readyFlag.current
    const _history = {
      text: JSON.stringify({
        _id: song._id,
        createdAt: new Date().toISOString(),
        coverId: song.coverId,
        songId: song._id,
        version: history_version,
        messageType: history_version,
      })
    }
    channel?.sendMessage(_history).then(() => {
      console.log('sent history message', _history)
    })
    resolve(JSON.parse(_history.text))
  })
}

return (<HistoryContext.Provider value={{
  history,
  readyFlag,
  addSongToHistory,
  importMetadata,
}}>{children} </HistoryContext.Provider>)
};

export default HistoryContext;

