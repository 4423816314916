import { styled, alpha } from "@mui/material/styles";

export const SearchField = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(
    theme.palette.mode === "dark"
      ? theme.palette.common.white
      : theme.palette.grey[400],
    0.15
  ),
  "&:hover": {
    backgroundColor: alpha(
      theme.palette.mode === "dark"
        ? theme.palette.common.white
        : theme.palette.grey[400],
      0.25
    ),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

export default SearchField;
