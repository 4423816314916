import React from 'react';
import { Avatar, Box, Card, CardActionArea, CardContent, CardMedia, Grid, Stack, Typography } from "@mui/material";
import SwipeableViews from 'react-swipeable-views';
import { deepOrange } from '@mui/material/colors';
import AlbumsContext from 'contexts/core/AlbumsContext';
import SongsContext from 'contexts/core/SongsContext';
import PlayerContext from 'contexts/core/PlayerContext';
import CoverFlow from 'components/CoverFlow';

const MusicPage = () => {
    const albumsContext = React.useContext(AlbumsContext)
    const songsContext = React.useContext(SongsContext)
    const playerContext = React.useContext(PlayerContext)
    const [recentlyAdded, setRecentlyAdded] = React.useState<any[]>([])
    const [slideSelected, setSlideSelected] = React.useState<number>(0)

    React.useEffect(() => {
        console.log('albumsContext.albums', albumsContext.albums)
        albumsContext.albums.forEach((album: any) => {
            console.log('herer', album)
        })
        if (albumsContext.albums.length > 0) {
            const sorted = albumsContext.albums.sort((a, b) => {
                return dateToTimestamp(new Date(b.createdAt)) - dateToTimestamp(new Date(a.createdAt))
            })
            setRecentlyAdded(sorted.slice(0, 5))
        }
    }, [albumsContext.albums])

    const dateToTimestamp = (date: Date) => {
        return date.getTime()
    }
    const play = async (album: any) => {
        const playList = []
        console.log('album', album)
        console.log('songsContext.songs', songsContext.songs)
        for (let x in album.songs) {
            const song = album.songs[x]
            const _song = await songsContext.getSong(song)
            playList.push(_song)
        }
        console.log('playList', playList)
        // for (let x in album.songs) {
        //     const song = album.songs[x]
        //     console.log(album)
        //     if (songs.has(song.shardId)) {
        //         const _song = songs.get(song.shardId)
        //         _song.title = song.name
        //         _song.artist = album.artist
        //         _song.album = album.title
        //         _song.coverImg = album.coverImgUrl
        //         _song.cover = album.cover
        //         playList.push(_song)
        //     }
        // }
        playerContext.setPlaylist(playList)
    }

    const updateSlideSelected = (index: number) => {
        setSlideSelected(index)
    }


    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            sx={{
                padding: 0, width: '100%', background: 'none',
            }}
        >
            <Grid item xs={12}>
                <Typography variant="h5" component="div" sx={{ flexGrow: 1 }} align='center' gutterBottom>
                    Music
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} align='left' gutterBottom>
                    Recently Added
                </Typography>
            </Grid>
            <Grid xs={12} item>
                <SwipeableViews style={{ padding: 0 }} resistance onSwitching={updateSlideSelected}>
                    {recentlyAdded.map((album: any, index) => {
                        return (
                            <Card key={index} sx={{ width: '100%' }} elevation={3} onClick={() => { play(album) }}>
                                <CardActionArea sx={{ position: "relative" }}>
                                    <CardMedia
                                        sx={{ maxHeight: 225 }}
                                        component="img"
                                        height="100%"
                                        image={`/images/albumn_background${index + 1}.png`}
                                        alt={album.title}
                                    />
                                    <Box sx={{ display: 'flex', flexDirection: 'row', position: "absolute", bottom: 0, backgroundColor: 'rgba(0, 0, 0, 0.75)', width: '100%', pt: 0, pb: 0 }}>
                                        <CardContent sx={{ flex: 'grow' }}>
                                            {album.cover ? <Avatar sx={{ width: 56, height: 56 }} src={album.cover.img?.href} /> : null}
                                        </CardContent>
                                        <CardContent sx={{ flex: 'auto' }}>
                                            <Typography variant="h6" component="div">
                                                {album.title}
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary">
                                                {album.artist}
                                            </Typography>
                                        </CardContent>
                                    </Box>
                                </CardActionArea>

                            </Card>
                        )
                    })}
                </SwipeableViews>

            </Grid>
            {recentlyAdded.length > 1 ?
                <Grid item>
                    <Stack direction="row">
                        {recentlyAdded.map((album: any, index) => {
                            return (
                                <Avatar key={index} sx={{ width: 6, height: 6, mr: 0.5, bgcolor: slideSelected === index ? deepOrange[500] : '#FFF' }}>{' '}</Avatar>
                            )
                        })}
                    </Stack>
                </Grid> :
                null}


            <Grid item xs={12}>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} align='left' gutterBottom>
                    Recently Played
                </Typography>
            </Grid>


            <Grid xs={12} item>
                <CoverFlow />
            </Grid>


        </Grid>
    )
}

export default MusicPage;


