import React from 'react';
import { Card, CardActionArea, CardMedia, Grid, IconButton, Typography, LinearProgress, Skeleton, Box, Paper, Avatar } from "@mui/material";
import FastRewindIcon from '@mui/icons-material/FastRewind';
import FastForwardIcon from '@mui/icons-material/FastForward';
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import ShuffleIcon from '@mui/icons-material/Shuffle';
import ExpandMore from '@mui/icons-material/ExpandMore';
import RepeatIcon from '@mui/icons-material/Repeat';
import RepeatOnIcon from '@mui/icons-material/RepeatOn';
import RepeatOneIcon from '@mui/icons-material/RepeatOne';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import CloseIcon from '@mui/icons-material/Close';
import { getHue } from 'utils/Misc';
import HistoryContext from 'contexts/core/HistoryContext';
import Slide from '@mui/material/Slide';
import { Song } from './SongsContext';
import { max } from 'lodash';

interface NowPlaying extends Song {
    img: string
    audioUrl: string
}

interface IPlayerContext {
    setPlaylist: (playlist: any[]) => void
    bgColor: string
    minimize: () => void
    maximize: () => void
    nowPlaying?: NowPlaying
    position?: number | null
    duration?: number | null
    progress?: number
    volume: number
    formatTime: (seconds: number) => string
    playing: boolean
    playLast: () => void
    playOrPause: () => void
    playNext: () => void
    mute: () => void
    unmute: () => void
    toggleShuffleSongs: () => void
    shuffle: boolean
    toggleRepeatState: () => void
    repeat: number
    isMini: boolean
    ready: boolean
    stop: () => void
}

const PlayerContext = React.createContext<IPlayerContext>({
    setPlaylist: () => { },
    bgColor: 'rgba(0, 0, 0, 0.75)',
    minimize: () => { },
    maximize: () => { },
    nowPlaying: undefined,
    position: null,
    duration: null,
    progress: 0,
    volume: 0,
    formatTime: () => { return '' },
    playing: false,
    playLast: () => { },
    playOrPause: () => { },
    playNext: () => { },
    mute: () => { },
    unmute: () => { },
    toggleShuffleSongs: () => { },
    shuffle: false,
    toggleRepeatState: () => { },
    repeat: 0,
    isMini: false,
    ready: false,
    stop: () => { }
})

export default PlayerContext

export const Player = () => {
    const player = React.useContext(PlayerContext)
    return (
        <Box style={{ backgroundColor: '#121212' }}>
            <Slide direction="up" in={player.ready && !player.isMini && player.playing}>
                <Box
                    sx={{
                        height: '100vh',
                        width: '100vw',
                        position: 'fixed',
                        zIndex: 1,
                        backgroundColor: '#444',
                    }}
                >

                    <Grid
                        container
                        direction="column"
                        justifyContent="flex-end"
                        alignItems="center"
                        sx={{
                            padding: 3, width: '100%', background: 'none', height: '100vh',
                            paddingBottom: 14,
                            position: 'relative',
                            backgroundColor: player.bgColor,
                            transition: 'background-color 0.3s ease'
                        }}
                    >
                        <IconButton onClick={player.stop} sx={{ position: 'absolute', top: '12px', left: '16px' }} aria-label="previous">
                            <CloseIcon sx={{ fontSize: 36 }} />
                        </IconButton>
                        <IconButton onClick={player.minimize} sx={{ position: 'absolute', top: '12px', right: '16px' }} aria-label="previous">
                            <ExpandMore sx={{ fontSize: 36 }} />
                        </IconButton>
                        <Grid sx={{ width: '100%', pb: '30%' }} item>
                            <Grid
                                container
                                direction="row"
                                justifyContent="space-evenly"
                                alignItems="center"

                            >
                                {player.nowPlaying && player.nowPlaying?.img ?
                                    <Card sx={{ width: '70%' }} elevation={3}>
                                        <CardActionArea sx={{ position: "relative" }}>
                                            <CardMedia
                                                component="img"
                                                image={player.nowPlaying.img}
                                                alt={'cover image'}
                                            />
                                        </CardActionArea>

                                    </Card>
                                    : <Skeleton variant="rectangular" width={'70%'} height={'70%'} />

                                }

                            </Grid>
                        </Grid>
                        <Grid sx={{ width: '80%', pb: 2 }} item>
                            <Grid
                                container
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <Grid xs={12} sx={{ pb: 2 }} item>
                                    {!player.nowPlaying?.title ? <Skeleton variant="text" sx={{ fontSize: '1rem', width: '75%' }} /> :
                                        <Typography variant="subtitle1" component="div" sx={{ flexGrow: 1 }} align='left' gutterBottom>
                                            {player.nowPlaying?.title}
                                        </Typography>
                                    }
                                    {!player.nowPlaying?.artist ? <Skeleton variant="text" sx={{ fontSize: '1.5rem', width: '50%' }} /> :
                                        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} align='left' gutterBottom>
                                            {player.nowPlaying?.artist}
                                        </Typography>
                                    }
                                </Grid>
                                <Grid xs={12} item>
                                    <LinearProgress sx={{ width: '100%', height: 8, borderRadius: 4 }} variant='determinate' color='inherit' value={player.progress} />
                                </Grid>
                                <Grid item>
                                    <Typography variant="overline" component="div" sx={{ flexGrow: 1 }} align='left' gutterBottom>
                                        {player.position ? player.formatTime(player.position) : '0:00'}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant="overline" component="div" sx={{ flexGrow: 1 }} align='left' gutterBottom>
                                        {player.duration ? player.formatTime(player.duration) : '0:00'}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid sx={{ width: 'calc(80% + 16px)', pb: 4 }} item>
                            <Grid
                                container
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <Grid item>
                                    <IconButton disabled={!player.nowPlaying} onClick={player.playLast} aria-label="previous">
                                        <FastRewindIcon sx={{ fontSize: 36 }} />
                                    </IconButton>
                                </Grid>
                                <Grid item>
                                    <IconButton disabled={!player.nowPlaying} onClick={player.playOrPause} aria-label="previous">
                                        {!player.playing ? <PlayCircleFilledWhiteIcon sx={{ fontSize: 64 }} /> : <PauseCircleOutlineIcon sx={{ fontSize: 64 }} />}
                                    </IconButton>
                                </Grid>
                                <Grid item>
                                    <IconButton disabled={!player.nowPlaying} onClick={player.playNext} aria-label="previous">
                                        <FastForwardIcon sx={{ fontSize: 36 }} />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid sx={{ width: 'calc(80% + 16px)' }} item>
                            <Grid
                                container
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <Grid item>
                                    {player.volume > 0 ?
                                        <IconButton disabled={!player.nowPlaying} onClick={player.mute} aria-label="mute">
                                            <VolumeOffIcon sx={{ fontSize: 24 }} />
                                        </IconButton> :
                                        <IconButton disabled={!player.nowPlaying} onClick={player.unmute} aria-label="unmute">
                                            <VolumeUpIcon sx={{ fontSize: 24 }} />
                                        </IconButton>
                                    }
                                </Grid>
                                <Grid item>
                                    <IconButton disabled={!player.nowPlaying} onClick={player.toggleShuffleSongs} aria-label="shuffle">
                                        <ShuffleIcon sx={{ fontSize: 24 }} color={player.shuffle ? 'primary' : 'disabled'} />
                                    </IconButton>
                                </Grid>
                                <Grid item>
                                    <IconButton disabled={!player.nowPlaying} onClick={player.toggleRepeatState} aria-label="shuffle">
                                        {player.repeat === 0 ? <RepeatIcon sx={{ fontSize: 24 }} color={'disabled'} /> : null}
                                        {player.repeat === 1 ? <RepeatOnIcon sx={{ fontSize: 24 }} color={'primary'} /> : null}
                                        {player.repeat === 2 ? <RepeatOneIcon sx={{ fontSize: 24 }} color={'primary'} /> : null}
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                </Box>
            </Slide>
        </Box>
    )
}


export const MiniPlayer = () => {
    const player = React.useContext(PlayerContext)

    return (
        <Box style={{ backgroundColor: 'transparent' }}>
            <Slide direction="up" in={player.ready && player.isMini && player.playing}>
                <Box
                    sx={{
                        height: 96,
                        position: 'fixed',
                        bottom: 100,
                        width: 'calc(100vw - 32px)',
                        zIndex: 1,
                        padding: 2,
                        pt: 0,
                        pb: 0,
                    }}
                >
                    <Grid
                        elevation={4}
                        component={Paper}
                        sx={{
                            position: 'absolute',
                            height: '100%',
                            pl: 2,
                            flexGrow: 1,
                        }}
                        direction={'row'}
                        justifyContent={'space-between'}
                        alignItems={'center'}
                        container>
                        <IconButton onClick={player.stop} sx={{ position: 'absolute', top: '-4px', right: '32px' }} aria-label="previous">
                            <CloseIcon sx={{ fontSize: 24, color: 'gray' }} />
                        </IconButton>
                        <IconButton onClick={player.maximize} sx={{ position: 'absolute', top: '-4px', right: '-4px' }} aria-label="previous">
                            <FullscreenIcon sx={{ fontSize: 24, color: 'gray' }} />
                        </IconButton>
                        <Grid direction={'row'}
                            justifyContent={'flex-start'}
                            alignItems={'center'}
                            sx={{ width: '65%' }}
                            container>
                            <Grid item>
                                <Avatar sx={{ width: 56, height: 56, mr: 2 }} alt={player.nowPlaying?.artist} src={player.nowPlaying?.img} variant="rounded" />
                            </Grid>
                            <Grid item>
                                <Typography variant="subtitle1" component="div" align='left' gutterBottom>
                                    {player.nowPlaying?.title}
                                </Typography>
                                <Typography variant="h6" component="div" align='left' gutterBottom>
                                    {player.nowPlaying?.artist}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid direction={'row'}
                            justifyContent={'flex-end'}
                            alignItems={'center'}
                            sx={{ width: '35%', pr: 1 }}
                            container>
                            <Grid item>
                                <IconButton disabled={!player.nowPlaying} onClick={player.playOrPause} aria-label="previous">
                                    {!player.playing ? <PlayCircleFilledWhiteIcon sx={{ fontSize: 36 }} /> : <PauseCircleOutlineIcon sx={{ fontSize: 36 }} />}
                                </IconButton>
                            </Grid>
                            <Grid item>
                                <IconButton disabled={!player.nowPlaying} onClick={player.playNext} aria-label="previous">
                                    <FastForwardIcon sx={{ fontSize: 36 }} />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Grid>

                </Box>
            </Slide >
        </Box >

    )
}


export const PlayerProvider = ({ children }: { children: React.ReactNode }) => {
    const originalPlaylist = React.useRef<any[]>([])
    const wakeLock = React.useRef<any>(null)
    const fullPlayerRef = React.useRef<HTMLElement>(null);
    const audioRef = React.useRef<HTMLAudioElement>(null);
    const historyContext = React.useContext(HistoryContext)
    const [playList, setPlaylist] = React.useState<any[]>([])
    const [nowPlaying, setNowPlaying] = React.useState<any>(null)
    const [track, setTrack] = React.useState<any>(0)
    const [lastTrack, setLastTrack] = React.useState<any>(0)
    const [playing, setPlaying] = React.useState<boolean>(false)
    const [bgColor, setBgColor] = React.useState<string>('rgba(0, 0, 0, 0.75)')
    const [volume, setVolume] = React.useState<number>(0)
    const [duration, setDuration] = React.useState<number | null>(null)
    const [position, setPosition] = React.useState<number | null>(null)
    const [progress, setProgress] = React.useState<number>(0);
    const [shuffle, setShuffle] = React.useState<boolean>(false)
    const [repeat, setRepeat] = React.useState<number>(0)
    const [ready, setReady] = React.useState<boolean>(false)
    const [isMini, setIsMini] = React.useState<boolean>(false)

    React.useEffect(() => {
        if (playing) {
            wakeLockDevice()
        } else {
            wakeLockRelease()
        }
        return () => {
            wakeLockRelease()
        }
    }, [playing])

    React.useEffect(() => {
        console.log('new playlist', playList)
        if (playList.length > 0 && JSON.stringify(playList) !== JSON.stringify(originalPlaylist.current)) {

            resetPlayer()
            setReady(true)
        }
    }, [playList])

    React.useEffect(() => {
        if (ready && playList.length > 0) {
            playTrack(0)
        }

    }, [ready, playList])

    React.useEffect(() => {
        if (nowPlaying && nowPlaying?.img) {
            getHue(nowPlaying?.img).then((c: any) => {
                setBgColor(`rgba(${c.r}, ${c.g}, ${c.b}, 0.75)`)
            })
            if ("mediaSession" in navigator) {
                navigator.mediaSession.metadata = new MediaMetadata({
                    title: nowPlaying.title,
                    artist: nowPlaying.artist,
                    album: nowPlaying.album,
                    artwork: [
                        {
                            src: nowPlaying?.img,
                            sizes: "512x512",
                        },
                    ],
                });
            }
        }
    }, [nowPlaying])

    React.useEffect(() => {
        if (audioRef.current) {
            audioRef.current.volume = volume / 100
        }
    }, [volume])

    React.useEffect(() => {
        if (audioRef.current) {
            audioRef.current.onloadedmetadata = () => {
                setDuration(audioRef.current?.duration as number)
            }
            setInterval(() => {
                setDuration(audioRef.current?.duration as number)
                setPosition(audioRef.current?.currentTime as number)
                setProgress((audioRef.current?.currentTime as number) / (audioRef.current?.duration as number) * 100)
            }, 500)
            if ("mediaSession" in navigator) {
                navigator.mediaSession.setActionHandler("play", () => {
                    setPlaying(true)
                    audioRef.current?.play()
                });
                navigator.mediaSession.setActionHandler("pause", () => {
                    setPlaying(false)
                    audioRef.current?.pause()
                });
                navigator.mediaSession.setActionHandler("stop", () => {
                    setPlaying(false)
                    audioRef.current?.pause()
                });
                navigator.mediaSession.setActionHandler("previoustrack", () => {
                    console.log(audioRef.current?.currentTime)
                    if (audioRef.current?.currentTime && audioRef.current?.currentTime < 5) {
                        playLast()
                    } else {
                        setProgress(0)
                        if (audioRef.current && audioRef.current.currentTime) {
                            audioRef.current.currentTime = 0;
                        }

                    }
                });
                navigator.mediaSession.setActionHandler("nexttrack", () => {
                    playNext()
                });
            }
        }
    }, [audioRef.current])

    React.useEffect(() => {
        if (progress >= 100) {
            playNext()
        }
    }, [progress])

    const wakeLockDevice = async () => {
        if ("wakeLock" in navigator) {
            try {
                wakeLock.current = await navigator.wakeLock.request('screen');
                document.addEventListener("visibilitychange", async () => {
                    if (wakeLock !== null && document.visibilityState === "visible") {
                        wakeLock.current = await navigator.wakeLock.request("screen");
                    }
                });
                wakeLock.current.addEventListener('release', () => {
                    console.log('Screen Wake Lock released:', wakeLock.current.released);
                });
                console.log('Screen Wake Lock released:', wakeLock.current.released);
            } catch (err: unknown) {
                if (err instanceof Error)
                    console.error(`${err.name}, ${err.message}`);
            }
        } else {
            console.warn('wake lock not supported')
        }
    }

    const wakeLockRelease = async () => {
        if ("wakeLock" in navigator) {
            try {
                await wakeLock.current.release();
                wakeLock.current = null;
            } catch (err: unknown) {
                if (err instanceof Error)
                    console.error(`${err.name}, ${err.message}`);
            }
        }
    }

    const minimize = () => {
        setIsMini(true)
    }

    const maximize = () => {
        setIsMini(false)
    }

    const formatTime = (seconds: number) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = Math.floor(seconds % 60);

        const formattedMinutes = String(minutes).padStart(2, '0');
        const formattedSeconds = String(remainingSeconds).padStart(2, '0');

        return `${formattedMinutes}:${formattedSeconds}`;
    }

    const playTrack = async (track: number) => {
        setNowPlaying(null)
        if (playList[track]) {

            historyContext.addSongToHistory(playList[track])
            const _np = playList[track];
            _np.fileURL = await _np.getSong()

            _np.audioUrl = _np.fileURL.href
            _np.img = await _np.getCover()
            console.log(_np, playList)
            setNowPlaying(_np)
            setTimeout(() => {
                setPlaying(true)
                audioRef.current?.play()
            }, 400)

        } else {
            console.error('no handle')
        }
    }

    const playOrPause = () => {
        if (!playing) {
            setPlaying(true)
            audioRef.current?.play()
        } else {
            setPlaying(false)
            audioRef.current?.pause()
        }
    }

    const playNext = () => {
        setProgress(0)
        setTrack((_track: number) => {
            console.log(_track)
            _track = shuffle ? Math.floor(Math.random() * playList.length) : _track
            console.log(_track, playList)
            if (repeat === 0) {
                if (_track + 1 >= playList.length) {
                    audioRef.current?.pause()
                    return _track
                } else {
                    playTrack(_track + 1)
                    return _track + 1
                }
            } else if (repeat === 1) {
                if (_track + 1 >= playList.length) {
                    playTrack(0)
                    return 0
                } else {
                    playTrack(_track + 1)
                    return _track + 1
                }
            } else if (repeat === 2) {
                playTrack(_track)
                return _track
            } else {
                audioRef.current?.pause()
                return _track
            }
        })
    }

    const playLast = () => {
        setProgress(0)
        setTrack((_track: number) => {
            if (repeat === 0 || repeat === 1) {
                if (_track - 1 < 0) {
                    playTrack(playList.length - 1)
                    return playList.length - 1
                } else {
                    playTrack(_track - 1)
                    return _track - 1
                }
            } else if (repeat === 2) {
                playTrack(_track)
                return _track
            }
        })
    }

    const mute = () => {
        setVolume(0)
    }

    const unmute = () => {
        setVolume(30)
    }

    const toggleShuffleSongs = () => {
        setShuffle(!shuffle)
    }

    const toggleRepeatState = () => {
        setRepeat((_repeat: number) => {
            const _rep = _repeat + 1 > 2 ? 0 : _repeat + 1
            return _rep
        })
    }
    const stop = () => {
        audioRef.current?.pause()
        resetPlayer()
        maximize()
    }

    const resetPlayer = () => {
        setNowPlaying(null)
        setTrack(0)
        setLastTrack(0)
        setPlaying(false)
        setIsMini(false)
        // setBgColor('rgba(0, 0, 0, 0.75)')
        setVolume(0)
        setDuration(null)
        setPosition(null)
        setProgress(0)
        setShuffle(false)
        setRepeat(0)
    }

    return (<PlayerContext.Provider value={{
        setPlaylist,
        bgColor,
        minimize,
        maximize,
        position,
        duration,
        progress,
        formatTime,
        playing,
        volume,
        playLast,
        playOrPause,
        playNext,
        mute,
        unmute,
        toggleShuffleSongs,
        shuffle,
        toggleRepeatState,
        repeat,
        isMini,
        ready,
        nowPlaying,
        stop
    }}>
        {children}
        <div style={{ display: 'none' }}>
            {nowPlaying && nowPlaying.audioUrl ?
                <audio controls ref={audioRef}>
                    <source src={nowPlaying.audioUrl} type={nowPlaying.mimeType} />
                    Your browser does not support the audio element.
                </audio>
                : null
            }

        </div>
    </PlayerContext.Provider>)
}
