// (c) 2023 384 (tm)

import { FileHelper } from './files';
import { readJpegHeader } from './images';
import { FileTable } from './fileTable';
import { SBServiceWorker } from '../boot/serviceWorker';

export const browser = {
    files: FileHelper,
    fileTable: FileTable,
    serviceWorker: SBServiceWorker,
    images: {
        readJpegHeader: readJpegHeader
    },
};
