import React, { memo } from 'react';
import { Box, Card, CardContent, CardMedia, Chip, Divider, Grid, IconButton, Stack, Typography } from "@mui/material";
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import SearchBar from 'components/SearchBar';
import AlbumsContext from 'contexts/core/AlbumsContext';
import SongsContext from 'contexts/core/SongsContext';
import PlaylistsContext from 'contexts/core/PlayListContext';

interface AddMusicProps extends React.PropsWithChildren<{}> {
    play: (album: any) => void
}

const SearchListItem = memo((props: any) => {
    const { item, onClick, playlistsContext } = props
    const [title, setTitle] = React.useState<string>('')
    const [artist, setArtist] = React.useState<string>('')
    const [coverImg, setCoverImg] = React.useState<string>('')
    const [album, setAlbum] = React.useState<string>('')
    const [favorites, setFavorites] = React.useState<any>({})
    const [isFavorite, setIsFavorite] = React.useState<boolean>(false)

    React.useEffect(() => {
        checkFavorite()
    }, [playlistsContext])

    React.useEffect(() => {
        setDetails()
    }, [item])

    const setDetails = async () => {

        if (!item.album) {
            await item?.cover?.readyFlag.current
            setCoverImg(item.cover?.img?.href)
            setTitle(item.title)
            setArtist(item.artist)
        } else {
            console.log(item)
            console.log(await item?.getCover())
            setTitle(item.title)
            setArtist(item.artist)
            setCoverImg(await item?.getCover())
            setAlbum(item.album)
            checkFavorite()
        }

    }

    const checkFavorite = () => {
        console.log(playlistsContext)
        // const favorite = playlistsContext.playLists.get('favorite')
        const _favorite = playlistsContext.playLists.find((playlist: any) => playlist._id === 'favorite')
        console.log(_favorite)
        if (_favorite) {
            setFavorites(_favorite)
            const _isFavorite = _favorite.songs.some((song: any) => song.shardId === item._id)
            console.log(_isFavorite, 'here')
            setIsFavorite(_isFavorite)
        }
    }

    const handleFavorite = () => {
        if (isFavorite) {
            playlistsContext.removeSongFromPlaylist('favorite', { shardId: item._id, name: item.title })
            setIsFavorite(false)
        } else {
            playlistsContext.addSongToPlaylist('favorite', { shardId: item._id, name: item.title })
            setIsFavorite(true)
        }
    }

    const play = () => {
        if (item.album) {
            console.log(item)
            onClick([item])
        }
    }



    if (coverImg === '') {
        return null
    }

    return (
        <Card sx={{ display: 'flex', mb: 2, justifyContent: item.album ? 'space-between' : 'flex-start' }} onClick={play} elevation={4}>
            <CardMedia
                component="img"
                sx={{ width: 144, height: 144 }}
                image={coverImg}
                alt="Live from space album cover"
            />
            <Box sx={{ display: 'flex', flexDirection: 'row', flexGrow: 1 }}>
                <CardContent>
                    <Typography variant="overline" component="div">
                        {item.album ? album : ''}
                    </Typography>
                    <Typography gutterBottom variant="h5" component="div">
                        {title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {artist}
                    </Typography>

                </CardContent>
            </Box>
            {item.album ?
                <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pr: 1 }}>

                    <IconButton onClick={handleFavorite} aria-label="favorite">
                        {isFavorite ? <FavoriteIcon /> : <FavoriteBorderIcon />}
                    </IconButton>
                </Box> : null
            }
        </Card>
    )
})

const MusicLibrary = (props: AddMusicProps) => {
    const albumsContext = React.useContext(AlbumsContext)
    const songsContext = React.useContext(SongsContext)
    const playlistsContext = React.useContext(PlaylistsContext)
    const [query, setQuery] = React.useState<string>('')
    const [filters, setFilters] = React.useState<string[]>(['albums', 'songs'])
    const [searchItems, setSearchItems] = React.useState<any[]>([])




    React.useEffect(() => {
        if (query.length > 0) {
            const _searchItems = []
            for (const album of albumsContext.albums) {
                if (album.title.toLowerCase().includes(query.toLowerCase())) {
                    _searchItems.push(album)
                }
            }
            for (const song of songsContext.songs) {
                if (song.title.toLowerCase().includes(query.toLowerCase())) {
                    _searchItems.push(song)
                }
            }
            setSearchItems(_searchItems)
        }
    }, [query])

    React.useEffect(() => {
        if (query.length === 0) {
            console.log('query is empty')

            const _searchItems: any[] = [
                ...(filters.includes('albums') ? albumsContext.albums : []),
                ...(filters.includes('songs') ? songsContext.songs : []),
                ...(filters.includes('playLists') ? playlistsContext.playLists : [])
            ]
            setSearchItems(_searchItems)
        }
    }, [albumsContext.albums, playlistsContext.playLists, query, songsContext.songs, filters])

    const play = async (playListItems: any) => {
        console.log(playListItems)
        const playList = []
        for (let x in playListItems.songs) {
            const song = playListItems.songs[x]
            const _song = await songsContext.getSong(song)
            playList.push(_song)
        }
        console.log('playList', playList)
        props.play(playList)
    }

    const updateQuery = (event: React.ChangeEvent<HTMLInputElement>) => {
        setQuery(event.target.value)
    }

    const closeSearch = () => {
        setQuery('')
    }

    const handleFilter = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
        const _filter = event.currentTarget.innerText.toLowerCase()
        if (filters.includes(_filter)) {
            setFilters(filters.filter((filter) => filter !== _filter))
        } else {
            setFilters([...filters, _filter])
        }
    }
    console.log(searchItems)
    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            sx={{
                padding: 0, width: '100%', background: 'none',
                mb: 7
            }}
        >
            <Grid item xs={12}>
                <Typography variant="h5" component="div" sx={{ flexGrow: 1 }} align='center' gutterBottom>
                    Library
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <SearchBar query={query} onChange={updateQuery} onClose={closeSearch} />
                <Stack direction="row" spacing={1} sx={{ width: '100%', mt: 2, mb: 2 }}>
                    <Chip label="Albums"
                        color={filters.includes('albums') ? 'primary' : 'default'}
                        onClick={handleFilter} />
                    {/* <Chip label="Artists"
                        color={filters.includes('artists') ? 'primary' : 'default'}

                        onClick={handleFilter} /> */}
                    <Chip label="Songs"
                        color={filters.includes('songs') ? 'primary' : 'default'}
                        onClick={handleFilter} />
                </Stack>
            </Grid>
            <Grid xs={12} sx={{ mb: 2 }} item>
                <Divider />
            </Grid>
            {query && searchItems.length > 0 ? <Grid xs={12} sx={{ mb: 2 }} item>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} align='center' gutterBottom>
                    Search Results
                </Typography>
            </Grid> : null}
            {searchItems.length > 0 ? searchItems.map((item: any, index: number) => {

                return (<Grid key={index} xs={12} md={3} item>
                    <SearchListItem item={item} playlistsContext={playlistsContext} onClick={() => play(item)} />
                </Grid>)

            }) : <Grid xs={12} sx={{ mb: 2 }} item>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} align='center' gutterBottom>
                    No Results
                </Typography>
            </Grid>}

        </Grid>
    )
}

export default memo(MusicLibrary);


