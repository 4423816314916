// (c) 2023 384 (tm)

// 384 file system

import { Snackabra, SBServer, SBChannelId, Interfaces } from '../snackabra/snackabra';

export class SBFileSystem {
    server: Snackabra;
    channelList: SBChannelId[] = [];

    constructor(sbConfig: SBServer) {
        // make sure we're given a valid config
        if (typeof sbConfig !== 'object') {
            throw new Error("SBFileSystem(): sbConfig is null (must give SBServer config)")
        }
        this.server = new Snackabra(sbConfig);
    }

    // basic upload single buffer
    uploadBuffer(myChannelId: SBChannelId, payload: BodyInit, name: string = ''): Promise<Interfaces.SBObjectHandle> {
        if (!myChannelId) {
            throw new Error("myChannelId is null")
        }
        return new Promise((resolve) => {
            if (!this.server.storage) {
                console.log("'this.server': ", this.server);
                throw new Error("storage not initialized")
            }
            this.server.storage.storeData(payload, 'p', myChannelId).then((res) => {
                res.fileName = name
                res.dateAndTime = new Date().toISOString()
                if (this.server.storage.shardServer) res.shardServer = this.server.storage.shardServer
                delete res["iv"] // hack
                delete res["salt"] // hack
                Promise.resolve(res.verification).then((v) => {
                    res.verification = v as string;
                    resolve(res)
                })
            })
        })
    }
    uploadBrowserFileList(myChannelId: SBChannelId, fileMap: Map<any, any>, bufferMap: Map<any, any>) {
        console.info('uploadBrowserFileList() not implemented yet')
        console.log(myChannelId, fileMap, bufferMap)
        // return new Promise((resolve) => {
        //     let promises: Promise<Interfaces.SBObjectHandle>[] = [];
        //     for (let i = 0; i < fileList.length; i++) {
        //         promises.push(this.uploadBuffer(myChannelId, fileList[i], fileList[i].name));
        //     }
        //     Promise.all(promises).then((res) => {
        //         resolve(res);
        //     });
        // });
    }

}
