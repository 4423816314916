import * as __ from "lib384"

interface Config extends __.SBServer {
  mode?: "development" | "production";
  wallet_from_384_os?: string;
  jwk_from_384_os?: JsonWebKey;
  salt: ArrayBufferLike;
  iterations: number;
}

if (!process.env.REACT_APP_CHANNEL_SERVER) {
  throw new Error("REACT_APP_CHANNEL_SERVER is not defined");
}

if (!process.env.REACT_APP_CHANNEL_SERVER_WS) {
  throw new Error("REACT_APP_CHANNEL_SERVER_WS is not defined");
}

if (!process.env.REACT_APP_STORAGE_SERVER) {
  throw new Error("REACT_APP_STORAGE_SERVER is not defined");
}

if (!process.env.REACT_APP_MODE) {
  throw new Error("REACT_APP_MODE is not defined");
}

// if (process.env.REACT_APP_MODE === 'development' &&  !process.env.REACT_APP_WALLET_FROM_384_OS) {
//   throw new Error("REACT_APP_WALLET_FROM_384_OS is not defined, this should be defined in development mode");
// }

if(!process.env.REACT_APP_JWK_FROM_384_OS) {
  console.warn("REACT_APP_JWK_FROM_384_OS is not defined");
}

if(!process.env.REACT_APP_WALLET_FROM_384_OS) {
  console.warn("REACT_APP_WALLET_FROM_384_OS is not defined");
}

if(!process.env.REACT_APP_SALT) {
  throw new Error("REACT_APP_SALT is not defined");
}

if(!process.env.REACT_APP_ITERATIONS) {
  throw new Error("REACT_APP_ITERATIONS is not defined");
}



export const config: Config = {
  channel_server: process.env.REACT_APP_CHANNEL_SERVER,
  channel_ws: process.env.REACT_APP_CHANNEL_SERVER_WS,
  storage_server: process.env.REACT_APP_STORAGE_SERVER,
  mode: process.env.REACT_APP_MODE as "development" | "production",
  wallet_from_384_os: process.env.REACT_APP_WALLET_FROM_384_OS ,
  jwk_from_384_os: process.env.REACT_APP_JWK_FROM_384_OS ? JSON.parse(process.env.REACT_APP_JWK_FROM_384_OS) : undefined,
  salt: new Uint8Array(JSON.parse(process.env.REACT_APP_SALT)),
  iterations: parseInt(process.env.REACT_APP_ITERATIONS),
};
