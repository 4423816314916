import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import Home from "./Home";
import { BrandingProvider } from "../contexts/BrandingContext";
import { SnackabraProvider } from "contexts/SnackabraContext";
import { SBFileHelperProvider } from "contexts/SBFileHelperContext";
import WalletContext, { WalletProvider } from "../contexts/WalletContext";
import { NotificationProvider } from "../contexts/NotificationContext";
import { AuthProvider } from "../contexts/AuthContext";
import Login from 'components/Login';
import { config } from "config";
import NavAppBar from "components/NavAppBar";
import { AlbumsProvider } from "contexts/core/AlbumsContext";
import { CoversProvider } from "contexts/core/CoversContext";
import { SongsProvider } from "contexts/core/SongsContext";
import { PlayListProvider } from "contexts/core/PlayListContext";
import { HistoryProvider } from "contexts/core/HistoryContext";
import { PlayerProvider, Player, MiniPlayer } from "contexts/core/PlayerContext";

const SBConfig = {
    channel_server: config.channel_server,
    channel_ws: config.channel_ws,
    storage_server: config.storage_server,
}

const AppRoutes = () => {
    return (
        <BrandingProvider>
            <SnackabraProvider config={SBConfig}>
                <SBFileHelperProvider config={SBConfig}>
                    <NotificationProvider>
                        <AuthProvider>
                            <WalletProvider>
                                <CoversProvider>
                                    <AlbumsProvider>
                                        <SongsProvider>
                                            <PlayListProvider>
                                                <HistoryProvider>
                                                    <PlayerProvider>
                                                        <CssBaseline />
                                                        <WalletContext.Consumer>
                                                            {({ id, identity }) => {
                                                                console.log(id, identity)
                                                                if (!id || !identity) {
                                                                    return <Login />
                                                                } else {
                                                                    return (<Router>
                                                                        <NavAppBar />
                                                                         <Player />
                                                                         <MiniPlayer/>
                                                                        <Routes>
                                                                            {/* <Route path="/404" element={<PageNotFound />} /> */}
                                                                            <Route path="*" element={<Home />} />
                                                                            {/* <Route path="*" element={<PageNotFound />} /> */}
                                                                        </Routes>
                                                                    </Router>)
                                                                }
                                                            }}

                                                        </WalletContext.Consumer>
                                                    </PlayerProvider>
                                                </HistoryProvider>
                                            </PlayListProvider>
                                        </SongsProvider>
                                    </AlbumsProvider>
                                </CoversProvider>
                            </WalletProvider>
                        </AuthProvider>
                    </NotificationProvider>
                </SBFileHelperProvider>
            </SnackabraProvider>
        </BrandingProvider>
    );
};

export default AppRoutes;
