import { Grid, Skeleton, Typography } from '@mui/material';
import React from 'react';
import SwipeableViews from 'react-swipeable-views';
import HistoryContext from '../contexts/core/HistoryContext';
import PlayerContext from 'contexts/core/PlayerContext';

const styles = {
    root: {
        padding: '8px 110px',
    },
    slide: {
        color: '#fff',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        display: 'flex',
    },
    img: {
        width: 110,
        height: 110,
        borderRadius: 8,
        display: 'block',
        marginBottom: 16,
    },
};




const CoverFlow = () => {
    const historyContext = React.useContext(HistoryContext)
    const playerContext = React.useContext(PlayerContext)
    const [index, setIndex] = React.useState(0);
    const [songs, setSongs] = React.useState<any[]>([])
    const [images, setImages] = React.useState<any[]>([])

    React.useEffect(() => {
        const getSongs = async () => {
            const _songs = []
            const _images = []
            for (let x in historyContext.history) {
                const song = historyContext.history[x]
                const image = await song.getCover()
                _images.push(image)
                _songs.push(song)
            }
            setImages(_images)
            setSongs(_songs)
        }
        if (historyContext.history.length > 0) {
            getSongs()
        }
    }, [historyContext.history])

    const handleChangeIndex = (index: number) => {
        setIndex(index);
    };

    const play = (song: any) => {
        playerContext.setPlaylist([song])
    }

    return (
        <SwipeableViews
            index={index}
            style={styles.root}
            onChangeIndex={handleChangeIndex}
            resistance
        >

            {songs.map((song: any, currentIndex: number) => {
                return (<Grid
                    onClick={() => play(song)}
                    key={currentIndex}
                    direction={'row'}
                    justifyContent={'center'}
                    alignItems={'flex-start'}
                    sx={{ width: 125, height: 150, padding: 0, overflow: 'hidden' }}
                    container>
                    <Grid item>
                        <img style={styles.img} src={images[currentIndex]} />
                    </Grid>
                    <Grid item>
                        <Typography variant="body2" component="div" textAlign={'center'} sx={{ flexGrow: 1 }} noWrap>
                            {song.title}
                        </Typography>
                    </Grid>
                </Grid>)
            })
            }

        </SwipeableViews>
    )
}

export default CoverFlow;