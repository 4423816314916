import React, { useState, ReactNode } from "react";
import SBFileHelperContext, { SBFileHelperContextContextType } from '../SBFileHelperContext';
import IndexedKV from "utils/IndexedKV";

type CoverHandle = {
  version: number | string,
  type: string,
  id: string,
  key: string,
  actualSize: number,
  verification: string,
  fileName: string,
  dateAndTime: Date | string
  mimeType: string,
}

export type CoverMetada = {
  imageId: string
  mimeType: string,
  fileName: string,
}


export interface CoverInterface {
  _id: string;
  handle: CoverHandle;
  img?: URL;
  ready: boolean;
  mimeType: string;
  readyResolver: Promise<boolean>;
  image: () => Promise<string>;
  SBFH: SBFileHelperContextContextType
}

class Cover implements CoverInterface {
  _id: string;
  handle: CoverHandle;
  img?: URL;
  ready: boolean = false;
  readyResolver: any;
  readyFlag: Promise<boolean> = new Promise((resolve) => {
    this.ready = true;
    this.readyResolver = resolve as any;
  });
  mimeType: string;
  SBFH: SBFileHelperContextContextType
  constructor(id: string, mimeType: string, handle: CoverHandle, SBFH: SBFileHelperContextContextType) {
    this._id = id;
    this.mimeType = mimeType;
    this.handle = handle;
    this.SBFH = SBFH
    this.setCoverImage()
  }

  image = async (): Promise<string> => {
    await this.readyFlag
    return this.img?.href as string
  }

  setCoverImage = async () => {
    try {
      const ab = await this.SBFH.download(this.handle)
      this.img = new URL(URL.createObjectURL(new Blob([ab], { type: this.mimeType })));
      this.readyResolver()
    } catch (e: unknown) {
      if (e instanceof Error) {
        console.error(e)
      } else {
        console.warn(e)
      }

    }

  }

}


export interface ICoversContextInterface {
  covers: Map<string, Cover>,
  addCover: (cover: ICoverMessageObject) => void
  addCoverMetadata: (coverMeta: ICoverMessageObject) => void
  getCover: (coverId: string) => Promise<CoverInterface>
}

interface ICoverMessageObject {
  _id: string;
  createdAt: string;
  user: { _id: string, name: string }
  mimeType: string;
  shardId: string;
  handle: CoverHandle;
}

const CoversContext = React.createContext<ICoversContextInterface>({
  covers: new Map(),
  addCover: () => { },
  addCoverMetadata: () => { },
  getCover: async () => { return {} as CoverInterface }
});

export const CoversProvider = ({ children }: { children: ReactNode }) => {
  const SBFH = React.useContext(SBFileHelperContext)
  let readyTimeout = React.useRef<any>(null);
  let knownCovers = React.useRef<Map<string, string>>(new Map());
  const readyResolver = React.useRef<any>(null)
  const readyFlag = React.useRef<Promise<boolean>>()
  const [ready, setReady] = useState<boolean>(false);
  const indexedKV = new IndexedKV({ db: 'covers', table: 'data' })
  const [covers] = useState<Map<string, Cover>>(new Map());
  const [coversMetadata] = useState<Map<string, ICoverMessageObject>>(new Map())
  React.useEffect(() => {

    readyFlag.current = new Promise((resolve) => {
      readyResolver.current = resolve
    })

  }, [])

  React.useEffect(() => {
    if (ready) {
      readyResolver.current(true)
    }
  }, [ready])

  const addCoverMetadata = async (coverMeta: ICoverMessageObject, save: boolean = true) => {
    console.log('asdasdadasdasfsdgdag')
    if (readyTimeout.current) clearTimeout(readyTimeout.current)
    readyTimeout.current = setTimeout(() => {
      setReady(true)
    }, 200)
    if (knownCovers.current.has(coverMeta._id)) return;
    knownCovers.current.set(coverMeta._id, coverMeta._id)
    coversMetadata.set(coverMeta._id, coverMeta)
    if (save) {
      indexedKV.add(coverMeta._id, coverMeta)
    }
  }

  const addCover = async (cover: ICoverMessageObject, save: boolean = true) => {
    console.log('asdasdadasdasfsdgdag')
    if (readyTimeout.current) clearTimeout(readyTimeout.current)
    readyTimeout.current = setTimeout(() => {
      setReady(true)
    }, 200)
    if (knownCovers.current.has(cover.shardId)) return;
    knownCovers.current.set(cover.shardId, cover.shardId)
    try {
      if (!covers.has(cover.shardId)) {
        const _cover = new Cover(cover.shardId, 'image/jpeg', cover.handle, SBFH)
        await _cover.readyFlag
        covers.set(cover.shardId, _cover)
        if (save) {
          indexedKV.add(cover.shardId, cover.handle)
        }

      } else {
        console.log("Cover already known");
      }
    } catch (error: unknown) {
      if (error instanceof Error) {
        console.error(error);
      }
    }
  }
  const getCover = async (coverId: string): Promise<CoverInterface> => {
    await readyFlag.current
    if (covers.has(coverId)) {
      return covers.get(coverId) as CoverInterface
    } else {
      const coverMeta = await indexedKV.getItem(coverId + '_metadata') as ICoverMessageObject
      const _cover = new Cover(coverId, coverMeta.mimeType, coverMeta.handle, SBFH)
      await _cover.readyFlag
      return _cover as CoverInterface
    }
  }

  return (
    <CoversContext.Provider value={{
      covers,
      addCover,
      addCoverMetadata,
      getCover,
    }}>
      {children}
    </CoversContext.Provider>
  )
};

export default CoversContext;

