import React, { memo } from 'react';
import { Divider, Grid, IconButton, Typography } from "@mui/material";
import PlayArrowOutlinedIcon from '@mui/icons-material/PlayArrowOutlined';
import PlaylistsContext from 'contexts/core/PlayListContext';
import SongsContext from 'contexts/core/SongsContext';
import PlayerContext from 'contexts/core/PlayerContext';
interface AddMusicProps extends React.PropsWithChildren<{}> {
    play: (album: any) => void
}

// const PlayListItem = memo((props: any) => {
//     const SBFH = React.useContext(SBFileHelperContext)
//     const SB = React.useContext(SBContext)
//     const wallet = React.useContext(WalletContext)
//     const { item, covers, onClick, playlists } = props
//     const [title, setTitle] = React.useState<string>('')
//     const [artist, setArtist] = React.useState<string>('')
//     const [coverImg, setCoverImg] = React.useState<string>('')
//     const [album, setAlbum] = React.useState<string>('')
//     const [isFavorite, setIsFavorite] = React.useState<boolean>(false)
//     const [cover, setCover] = React.useState<any>(null)

//     React.useEffect(() => {
//         console.log(item)

//         if (typeof item === 'undefined') {
//             return
//         }

//     }, [item])


//     const handleFavorite = () => {
//         if (!wallet.id || !SB) return
//         const channel = SB.channels[wallet.id] as any
//         if (isFavorite) {
//             const song = item
//             if (props.songs.has(song.shardId)) {
//                 const _song = props.songs.get(song.shardId)
//                 _song.title = song.name
//                 _song.artist = artist
//                 _song.album = title
//                 _song.coverImg = coverImg
//                 _song.cover = item.cover
//                 console.log(_song)
//                 console.log(favorites)
//                 //@ts-ignore
//                 favorites.songs = favorites.songs.filter((song: any) => song.shardId !== _song.shardId)
//                 console.log(favorites)
//                 const message = {
//                     text: JSON.stringify(favorites),
//                 }
//                 channel.sendMessage(message)
//             }
//             setIsFavorite(false)
//         } else {
//             const song = item
//             if (props.songs.has(song.shardId)) {
//                 const _song = props.songs.get(song.shardId)
//                 _song.title = song.name
//                 _song.artist = artist
//                 _song.album = title
//                 _song.coverImg = coverImg
//                 _song.cover = item.cover
//                 console.log(_song)
//                 console.log(favorites)
//                 //@ts-ignore
//                 favorites.songs.push(_song)
//                 const message = {
//                     text: JSON.stringify(favorites),
//                 }
//                 channel.sendMessage(message)
//             }
//             setIsFavorite(true)
//         }
//     }



//     if (coverImg === '') {
//         return null
//     }

//     return (
//         <Paper elevation={4} sx={{ mb: 2 }}>
//             <Grid
//                 sx={{ display: 'flex', padding: 1 }}
//                 direction={'row'}
//                 alignContent={'center'}
//                 justifyContent={'space-between'}
//                 container>

//                 <img src={coverImg} alt={title} style={{ height: 64, width: 64 }} />

//                 <Typography variant="h6" component="div">
//                     {title}
//                 </Typography>



//                 <IconButton onClick={handleFavorite} aria-label="favorite">
//                     {isFavorite ? <FavoriteIcon /> : <FavoriteBorderIcon />}
//                 </IconButton>


//             </Grid>

//         </Paper>
//     )
// })

const PlayLists = (props: AddMusicProps) => {
    const playlistsContext = React.useContext(PlaylistsContext)
    const playerContext = React.useContext(PlayerContext)
    const songsContext = React.useContext(SongsContext)
    const [playlists, setPlaylists] = React.useState<Map<string, any>>(new Map())

    const play = async (playListItems: any) => {
        console.log(playListItems)
        const playList = []
        for (let x in playListItems) {
            const song = playListItems[x].shardId
            const _song = await songsContext.getSong(song)
            playList.push(_song)
        }
        console.log('playList', playList)
        playerContext.setPlaylist(playList)
    }

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            sx={{
                padding: 0, width: '100%', background: 'none',
            }}
        >
            <Grid item xs={12}>
                <Typography variant="h5" component="div" sx={{ flexGrow: 1 }} align='center' gutterBottom>
                    Play Lists
                </Typography>
            </Grid>
            <Grid xs={12} sx={{ mb: 2 }} item>
                <Divider />
            </Grid>
            {playlistsContext.playLists.length > 0 ? playlistsContext.playLists.map((value, _playListIndex) => {
                return (<Grid key={_playListIndex} xs={12} item>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} align='left' gutterBottom>
                            {value.title}
                        </Typography>
                        <IconButton onClick={() => play(value.songs)} aria-label="favorite">
                            <PlayArrowOutlinedIcon />
                        </IconButton>
                    </Grid>
                    {value.songs.length > 0 ?
                        <Typography variant="body2" component="div" sx={{ flexGrow: 1 }} align='center' gutterBottom>
                            Total Songs {value.songs.length}
                        </Typography>
                        : <Typography variant="body2" component="div" sx={{ flexGrow: 1 }} align='center' gutterBottom>
                            No Songs
                        </Typography>}


                </Grid>)
            }) :
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} align='left' gutterBottom>
                    Create a playlist to get started
                </Typography>}

        </Grid>
    )
}

export default memo(PlayLists);


