import React from 'react';
import { Button, Grid, LinearProgress, TextField, Typography } from "@mui/material";
import DropZone from 'components/DropZone';
import { isMobile } from 'react-device-detect';
import SBFileHelperContext from '../contexts/SBFileHelperContext';
import WalletContext from '../contexts/WalletContext';
import SBContext from '../contexts/SnackabraContext';
import { ChannelStoreType } from 'react384';
import _ from 'lodash';



const AddMusic = () => {
    const SB = React.useContext(SBContext)
    const wallet = React.useContext(WalletContext)
    const SBFH = React.useContext(SBFileHelperContext);
    const [artist, setArtist] = React.useState<string>('')
    const [title, setTitle] = React.useState<string>('')
    const [image, setImage] = React.useState<string | null>(null)
    const [songList, setSongList] = React.useState<string[]>([])
    const [showProgress, setShowProgress] = React.useState<boolean>(false)

    const parseImage = () => {
        if (!SBFH.fileHelper || !SBFH.fileHelper.finalFileList) return;
        for (const [key, value] of SBFH.fileHelper.finalFileList.entries()) {
            if (value.type.match(/image\/.*/)) {
                const ab = SBFH.fileHelper.globalBufferMap.get(value.uniqueShardId)

                const blob = new Blob([ab], { type: value.type });
                const reader = new FileReader();

                reader.onload = function () {
                    const dataURI = reader.result;
                    console.log(dataURI);

                    setImage(dataURI as string)
                };

                reader.readAsDataURL(blob);
            }
        }
    }
    const parseAudio = () => {
        if (!SBFH.fileHelper || !SBFH.fileHelper.finalFileList) return;
        for (const [key, value] of SBFH.fileHelper.finalFileList.entries()) {
            if (value.type.match(/audio\/.*/)) {
                console.log(value)
                setSongList(_songList => {
                    return [..._songList, value.name]
                })
            }
        }
    }

    const onSubmit = async () => {
        if (!SBFH.fileHelper || !SBFH.fileHelper.finalFileList || !wallet.id || !SB) return;
        if (!artist || !title || !image || songList.length === 0) {
            alert('Please fill out all fields')
            return;
        }
        const channel = SB.channels[wallet.id] as ChannelStoreType
        const songs = [] as { shardId: string, name: string }[]
        let cover = ''
        let _coverMetaData = {}
        setShowProgress(true)
        for (const [key, value] of SBFH.fileHelper.finalFileList.entries()) {

            if (value.type.match(/image\/.*/)) {
                console.log(value)
                const handle = await SBFH.uploadFile(value.uniqueShardId, 'image', wallet.id)
                cover = value.uniqueShardId
                _coverMetaData = {
                    imageId: value.uniqueShardId,
                    mimeType: value.type,
                    fileName: value.name,
                    handle: handle,
                    messageType: 'image_meta_data_v2'
                }
                const coverMetaData = {
                    text: JSON.stringify(_coverMetaData)
                }
                channel.sendMessage(coverMetaData)

            }
            if (value.type.match(/audio\/.*/)) {
                songs.push({ shardId: value.uniqueShardId, name: value.name })
                SBFH.uploadFile(value.uniqueShardId, 'audio', wallet.id).then((handle) => {

                    const songMetaData = {
                        text: JSON.stringify({
                            createdAt: new Date(),
                            artist: artist,
                            title: title,
                            coverId: cover,
                            mimeType: value.type,
                            fileName: value.name,
                            songId: value.uniqueShardId,
                            handle: handle,
                            messageType: 'song_meta_data_v2'
                        })
                    }
                    channel.sendMessage(songMetaData)
                })
            }
        }
        const album = {
            text: JSON.stringify({
                createdAt: new Date(),
                user: SB.getContact(channel.key),
                sender_username: SB.getContact(channel.key).name,
                artist: artist,
                title: title,
                songs: songs,
                coverMetaData: _coverMetaData,
                messageType: 'album_v7'
            })
        }
        channel.sendMessage(album)
    }

    const updateArtist = (e: React.ChangeEvent<HTMLInputElement>) => {
        setArtist(e.target.value)
    }

    const updateTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTitle(e.target.value)
    }
    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={2}
            sx={{ padding: 0, width: '100%', background: 'none' }}
        >
            <Grid item xs={12}>
                <Typography variant="h4" component="div" sx={{ flexGrow: 1 }} align='center'>
                    Add Music
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} align='center'>
                    Details
                </Typography>
                <TextField sx={{ mb: 2 }} label="Artist" variant="outlined" size='small' onChange={updateArtist} fullWidth />
                <TextField label="Album" variant="outlined" size='small' onChange={updateTitle} fullWidth />
                <Button sx={{ mt: 2, mb: 2 }} variant="contained" onClick={onSubmit} fullWidth>
                    Submit
                </Button>
                {showProgress ? <LinearProgress variant="determinate" value={SBFH.progress} /> : null}

            </Grid>
            <Grid item xs={6}>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} align='center'>
                    Upload Album Art
                </Typography>
                <DropZone style={{ borderStyle: 'dashed' }} accept={{ 'image/*': [] }} onDropCallback={parseImage}>
                    <Grid container
                        direction={'row'}
                        justifyContent={'center'}
                        alignContent={'center'}
                        sx={{ height: isMobile ? '100%' : 180 }}>
                        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} align='center'>

                            {isMobile ? 'Tap to Upload' : 'Drag and Drop'}
                        </Typography>
                    </Grid>
                </DropZone>
                {
                    image ?

                        <Grid sx={{ width: '100%', mt: 1 }} item>
                            <img src={image} style={{ width: '100%' }} alt="album cover" />
                        </Grid>
                        :
                        null
                }
            </Grid>
            <Grid item xs={12}>

            </Grid>
            <Grid item xs={12}>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} align='center'>
                    Upload Music
                </Typography>
                <DropZone style={{ borderStyle: 'dashed' }} accept={{ 'audio/mpeg': ['.mp3'] }} onDropCallback={parseAudio}>
                    <Grid container
                        direction={'row'}
                        justifyContent={'center'}
                        alignContent={'center'}
                        sx={{ height: isMobile ? '100%' : 180 }}>
                        <Grid item>
                            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} align='center'>

                                {isMobile ? 'Tap to Upload' : 'Drag and Drop'}
                            </Typography>
                        </Grid>
                    </Grid>
                </DropZone>
            </Grid>
            <Grid item xs={12}>
                {songList.map((song, index) => {
                    return (
                        <Grid key={index} item xs={12}>
                            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} align='left'>
                                {index + 1} {song}
                            </Typography>
                        </Grid>
                    )

                })}
            </Grid>

        </Grid>
    )
}

export default AddMusic;


