import React, { useState, ReactNode } from "react";
import CoversContext, { CoverInterface, ICoversContextInterface } from "./CoversContext";
import SBFileHelperContext, { SBFileHelperContextContextType } from '../SBFileHelperContext';
import * as _ from "lodash";

type SongHandle = {
  version: number | string,
  type: string,
  id: string,
  key: string,
  actualSize: number,
  verification: string,
  fileName: string,
  dateAndTime: Date | string
  mimeType: string,
}

type SongMetada = {
  createdAt: string | Date,
  artist: string,
  title: string,
  coverId: string,
  mimeType: string,
  fileName: string,
  songId: string,
}

export interface SongInterface {
  _id: string;
  title: string;
  artist: string;
  album: string;
  handle: SongHandle;
  mimeType: string;
  coverId: string;
  getSong: () => Promise<URL | undefined>;
}

export class Song implements SongInterface {
  _id: string;
  title: string;
  album: string;
  artist: string;
  handle: SongHandle;
  mimeType: string;
  SBFH: SBFileHelperContextContextType
  coverId: string;
  private covers: ICoversContextInterface
  constructor(id: string, album: string, title: string, artist: string, mimeType: string, handle: SongHandle, coverId: string, SBFH: SBFileHelperContextContextType, covers: ICoversContextInterface) {
    this._id = id;
    this.album = album;
    this.title = title.replace('.mp3', '').replace(artist + ' - ', '');
    this.artist = artist;
    this.coverId = coverId;
    this.handle = handle;
    this.mimeType = mimeType;
    this.SBFH = SBFH
    this.covers = covers

  }

  getCover = async (): Promise<string> => {
    const cover = await this.covers.getCover(this.coverId)
    return await cover.image()
  }

  getSong = async (): Promise<URL | undefined> => {
    try {
      const ab = await this.SBFH.download(this.handle)
      return new URL(URL.createObjectURL(new Blob([ab], { type: this.mimeType })));
    } catch (e: unknown) {
      if (e instanceof Error) {
        console.error(e)
      } else {
        console.warn(e)
      }

    }
  }
}

interface ISongsContextInterface {
  songs: Song[],
  addSong: (song: ISongMessageObject) => void
  getSong: (songId: string) => Promise<Song>
  readyFlag: React.MutableRefObject<Promise<boolean> | undefined>
}

interface ISongMessageObject extends SongMetada {
  createdAt: string;
  user: { _id: string, name: string }
  mimeType: string;
  shardId: string;
  handle: SongHandle;
}


const SongsContext = React.createContext<ISongsContextInterface>({
  songs: [],
  addSong: () => { },
  getSong: async () => { return {} as Song },
  readyFlag: { current: undefined }
});

export const SongsProvider = ({ children }: { children: ReactNode }) => {

  const SBFH = React.useContext(SBFileHelperContext)
  let readyTimeout = React.useRef<any>(null);
  let knownSongs = React.useRef<Map<string, string>>(new Map());
  const readyResolver = React.useRef<any>(null)
  const readyFlag = React.useRef<Promise<boolean>>()
  const covers = React.useContext(CoversContext)
  const [songs, setSongs] = useState<Song[]>([]);


  React.useEffect(() => {

    readyFlag.current = new Promise((resolve) => {
      readyResolver.current = resolve
    })

  }, [])

  React.useEffect(() => {
    readyTimeout.current = setTimeout(() => {
      readyResolver.current(true)
    }, 200)
  }, [songs])

  const addSong = async (song: ISongMessageObject) => {
    console.log('asdasdadasdasfsdgdag')
    if (readyTimeout.current) clearTimeout(readyTimeout.current)
    try {
      const _song = new Song(song.songId, song.title, song.fileName, song.artist, song.mimeType, song.handle, song.coverId, SBFH, covers);
      setSongs(_songs => _.uniqBy([..._songs, _song], '_id'));

    } catch (error: unknown) {
      if (error instanceof Error) {
        console.error(error);
      }
    }
  }

  const getSong = async (songId: string): Promise<Song> => {
    await readyFlag.current
    return songs.filter((song: Song) => song._id === songId)[0];
  }

  return (<SongsContext.Provider value={{
    readyFlag,
    songs,
    addSong,
    getSong
  }}>{children} </SongsContext.Provider>)
};

export default SongsContext;

