import React, { ReactNode } from "react"
import { useContext, useEffect } from "react";
import AuthContext from "./AuthContext";
import NotificationContext from "./NotificationContext";
import IndexedKV from "utils/IndexedKV";
import * as __ from 'lib384/dist/384.esm.js'
import { config } from "config";


const seedChannel = {
  "channelId": "f1r7X7TMNvmeA637At3tEL9PfSoWscU3QD68PtpRELRdTDYl8Hm62RL3CRYbxh0i",
  "key": {
    "crv": "P-384",
    "d": "xPypAS8RUWeOdsqf1mjbtYHyod7GaHPki6wOz5Dx2peJg1JeNX73d5xSVG3TQFcN",
    "ext": true,
    "key_ops": [
      "deriveKey"
    ],
    "kty": "EC",
    "x": "f_gqKfwPOCcw6gaeXRoRGPx3LXMKuScQ3XCVvqLcnsMU6pXgsMC_lJGSCHACIkLh",
    "y": "YtVbsYyh2ZGIwAObIt6sgltPOYZWaFqY1m3FeECfVcWXXtHfOOTmxvqkHC3f1PL4"
  },
  "server": "https://channel.384.dev"
}

//https://preview.384chat.pages.dev/f1r7X7TMNvmeA637At3tEL9PfSoWscU3QD68PtpRELRdTDYl8Hm62RL3CRYbxh0i

interface IWallet {
  channelId?: string
  key?: JsonWebKey
  walletId: string
  originalLimit: number
}
interface IWalletContextInterface {
  wallet: IWallet | null,
  id: string | null,
  identity: any,
  setWallet: React.Dispatch<React.SetStateAction<any>>,
  destroy: () => void,
  loadFromFile: (file: any) => void,
  setStrongPinJwk: (jwk: JsonWebKey) => void,
  registerWallet: (walletId: string) => void
}

const WalletContext = React.createContext<IWalletContextInterface>({
  wallet: null,
  id: null,
  identity: null,
  setWallet: () => { },
  destroy: () => { },
  loadFromFile: () => { },
  setStrongPinJwk: () => { },
  registerWallet: () => { }
});

export const WalletProvider = ({ children }: { children: ReactNode }) => {
  const walletDb = new IndexedKV({ db: "data", table: "wallet" });
  const auth = useContext(AuthContext)
  const notify = useContext(NotificationContext)
  const [wallet, setWallet] = React.useState<IWallet | null>(null);
  const [identity, setIdentity] = React.useState<any>(null);
  const [id, setId] = React.useState<any>(null);
  const [registration, setRegistration] = React.useState<any>(null);

  useEffect(() => {
    loadWallet()
  }, [])

  useEffect(() => {
    console.log(config)
    if (!config.wallet_from_384_os || !config.jwk_from_384_os) return
    setId(config.wallet_from_384_os)
    setIdentity(config.jwk_from_384_os)
  }, [])

  useEffect(() => {
    if (!auth.isReady || !registration) return
    auth.encrypt(JSON.stringify(registration)).then((e) => {
      if (e) {
        console.log(e)
        walletDb.setItem(registration.channelId + '_wallet', encodeURIComponent(e.string()))
        auth.prompt(e.string())
      } else {
        notify.setMessage('something broke!');
        notify.setSeverity('error');
        notify.setOpen(true)
      }
    })
  }, [auth.isReady, registration])

  useEffect(() => {
    if (auth.afterAuth) {
      onAuth(auth.afterAuth)
    }
  }, [auth.afterAuth])

  const loadWallet = async () => {
    walletDb.openCursor('_wallet', (w: any) => {
      if (w.length === 0) return
      console.log(w)
      auth.prompt(decodeURIComponent(w[0].value))
    })
  }

  const onAuth = (d: string) => {
    const wallet = JSON.parse(d)
    setIdentity(wallet.key)
    setId(wallet.channelId)
    setWallet(wallet)
  }

  const loadFromFile = async (file: { walletId: string, originalLimit: string }) => {
    if (file.hasOwnProperty('_id') && file.hasOwnProperty('identity')) {
      registerWallet(file.walletId)
    } else {
      alert('File format is incorrect')
    }

  }

  const destroy = async () => {
    console.log('destroying wallet', id + '_wallet')
    walletDb.removeItem(id + '_wallet').then(() => {
      console.log('wallet destroyed')
      window.location.reload()
    }).catch((e) => {
      console.error(e)
    })

    // await

  }

  const setStrongPinJwk = (jwk: JsonWebKey) => {
    setIdentity(jwk)
  }

  const onMessage = async (msg: __.ChannelMessage) => {
    console.log(msg)
  }

  const connectWallet = async (walletId: string) => {
    console.log(walletId)
    const sb_config = {
      channel_server: 'https://channel.384co.workers.dev',
      channel_ws: 'wss://channel.384co.workers.dev',
      storage_server: 'https://storage.384co.workers.dev'
    };
    try {
      const SBServer = new __.NewSB.Snackabra(sb_config)
      console.log(identity,id)
      const sb384 = new __.NewSB.SB384(identity)
      await sb384.ready
      setIdentity(sb384.hash)
      const channelEndpoint = new __.NewSB.ChannelEndpoint(sb_config, identity)
      console.log(channelEndpoint)
      const c = await SBServer.connect(
        onMessage,
        identity,
        sb384.hash,
      );
      if (c) {
        const walletRegistration: IWallet = {
          channelId: c.channelId,
          key: identity,
          walletId: walletId,
          originalLimit: await c.api.getStorageLimit()
        }
        setRegistration(walletRegistration)
        return true
      }
      return false
    } catch (e) {
      console.error(e)
      return false
    }

  }

  const registerWallet = async (walletId: string) => {
    if (!identity) throw new Error('no identity')
    const connected = await connectWallet(walletId)
    console.log(connected)
    if (connected) return
    const sb_config = {
      channel_server: 'https://channel.384co.workers.dev',
      channel_ws: 'wss://channel.384co.workers.dev',
      storage_server: 'https://storage.384co.workers.dev'
    };
    console.log(auth)
    const channelEndpoint = new __.NewSB.ChannelEndpoint(sb_config, seedChannel.key, seedChannel.channelId)
    const SBServer = new __.NewSB.Snackabra(sb_config)
    SBServer.create(sb_config, channelEndpoint, identity).then(async (e) => {
      const storageChannel = new __.NewSB.ChannelEndpoint(sb_config, e.key, e.channelId)
      const walletRegistration: IWallet = {
        channelId: e.channelId,
        key: e.key,
        walletId: walletId,
        originalLimit: await storageChannel.api.getStorageLimit()
      }
      setRegistration(walletRegistration)
    })
  }

  return (<WalletContext.Provider value={{
    wallet,
    id,
    identity,
    setWallet,
    destroy,
    loadFromFile,
    setStrongPinJwk,
    registerWallet
  }}>{children} </WalletContext.Provider>)
};

export default WalletContext;

