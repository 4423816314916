import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import Routes from "./pages/Routes";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  // React.StrictMode causes double render in development, but not in production
  // <React.StrictMode>
  <Routes />
  // </React.StrictMode>
);

const onUpdate = (registration: ServiceWorkerRegistration) => {
  console.log('onUpdate', registration)
  if (registration.waiting) {
    registration.waiting.postMessage({ type: 'SKIP_WAITING' })
    window.location.reload()
  }
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
if (process.env.NODE_ENV === "production") serviceWorkerRegistration.register({
  onUpdate: onUpdate
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
