import React, { useEffect } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import SnackabraContext from 'contexts/SnackabraContext';
import { autorun } from 'mobx'
import {
  AppBar,
  Box,
  Grid,
  Hidden,
  IconButton,
  Toolbar,
  Tabs,
  Tab,
  Typography,
  Collapse,
  Slide,
  useTheme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  MoreVert,
  HomeOutlined,
  QueueMusic,
  LibraryMusicOutlined,
  ArrowBack,
} from "@mui/icons-material";
import { TransitionGroup } from "react-transition-group";
import Logo from "./icons/Logo";

const useStyle: Function = makeStyles({
  indicator: {
    top: "0px",
  },
});

const tabSx = {
  textTransform: "none",
  flexGrow: 1,
  pt: 0,
  pb: 0,
  fontSize: 12,
  height: 50,
  minHeight: 50,
};

const linkMap: {
  [key: string]: { index: number; label: string; back?: string };
} = {
  "/": { index: 0, label: "Home" },
  "/l": { index: 1, label: "Library" },
  "/p": { index: 2, label: "Playlists" },

  "/a": { index: 4, label: "Add" },
  // "/a/users": { index: 2, label: "Users", back: "/a" },
  // "/a/branding": { index: 2, label: "Branding", back: "/a" },
  // "/p": { index: 3, label: "You" },
  // "/p/edit": { index: 3, label: "Edit Profile", back: "/p" },
  // "/p/settings": { index: 3, label: "Settings", back: "/p" },
  // "/p/settings/appearance": { index: 3, label: "Appearance", back: "/p/settings" },
  // "/p/settings/message-layout": { index: 3, label: "Message Layout", back: "/p/settings" },
  // "/p/notifications": { index: 3, label: "Pause Notifications", back: "/p" },
};

const NavAppBar = () => {
  const theme = useTheme();
  const SBContext = React.useContext(SnackabraContext)
  const classes = useStyle();
  const location = useLocation();
  const containerRef = React.useRef<HTMLElement>(null);
  const [slideIn, setSlideIn] = React.useState(false);
  const [tabIndex, setTabIndex] = React.useState(0);
  const [loaded, setLoaded] = React.useState(false);
  const [rootPagePath, setRootPagePath] = React.useState("/");

  useEffect(() => {
    if (!linkMap[location.pathname]) {
      const segments = location.pathname.split("/");
      setTabIndex(linkMap[`/${segments[1]}`].index);
      setRootPagePath(`/${segments[1]}`);
    } else {
      setTabIndex(linkMap[location.pathname].index);
      setRootPagePath(location.pathname);
    }
  }, [location]);

  const changeTab = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  React.useEffect(() => {
    setTimeout(() => {
      setSlideIn(true);
    }, 2250);
  }, [loaded]);

  React.useEffect(() => {
    if (SBContext) {
      autorun(() => {
        setLoaded(true)
      })
    }
  }, [SBContext]);

  return (

    <Box sx={{ flexGrow: 1 }}>

      <Hidden smDown>
        <AppBar position="fixed" elevation={0}>
          <Toolbar
            sx={{
              minHeight: "46px",
            }}
          >

            {rootPagePath === "/" ? (
              <Logo sx={{ fontSize: 42 }} />
            ) : (
              <Box
                style={{
                  display: "inline-flex",
                  width: "100%",
                  justifyContent: "flex-start",
                }}
              >
                <TransitionGroup>
                  {typeof linkMap[rootPagePath]?.back !== "undefined" && (
                    <Collapse in orientation={"horizontal"}>
                      <IconButton
                        id={'nav-back-button'}
                        component={RouterLink}
                        to={linkMap[rootPagePath].back as string}
                      >
                        <ArrowBack sx={{ fontSize: 24 }} />
                      </IconButton>
                    </Collapse>
                  )}
                </TransitionGroup>
                <Typography
                  variant="h6"
                  sx={{ flexGrow: 1, fontSize: 20, m: "auto" }}
                >
                  {linkMap[rootPagePath].label}
                </Typography>
              </Box>
            )}

            <Grid sx={{ flexGrow: 1 }} />
            <Hidden smUp>
              <IconButton
                sx={{ bgcolor: "transparent", padding: 0 }}
                color="inherit"
              >
                <MoreVert sx={{ fontSize: 32 }} />
              </IconButton>
            </Hidden>
          </Toolbar>
        </AppBar>
      </Hidden>
      <Hidden smUp>
        <Slide in={slideIn} container={containerRef.current} direction='up' easing={{ enter: theme.transitions.easing.easeInOut }}>
          <AppBar
            position="fixed"
            color="default"
            elevation={0}
            sx={{ top: "auto", bottom: 0, pb: 4 }}
          >
            <Tabs
              sx={{ width: "100%" }}
              value={tabIndex}
              onChange={changeTab}
              aria-label="icon label tabs example"
              classes={{
                indicator: classes.indicator,
              }}
            >
              <Tab
                component={RouterLink}
                to={"/"}
                icon={<HomeOutlined />}
                label="Home"
                sx={tabSx}
              />
              {/* <Tab
                component={RouterLink}
                to={"/r"}
                icon={<RadioOutlined />}
                label="Player"
                sx={tabSx}
              /> */}
              <Tab
                component={RouterLink}
                to={"/l"}
                icon={<LibraryMusicOutlined />}
                label="Library"
                sx={tabSx}
              />
              <Tab
                component={RouterLink}
                to={"/p"}
                icon={<QueueMusic />}
                label="Playlists"
                sx={tabSx}
              />
            </Tabs>
          </AppBar>
        </Slide>
      </Hidden>

    </Box>

  );
};

export default NavAppBar;
