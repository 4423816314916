import React from "react";
import { createSvgIcon } from "@mui/material/utils";

const Logo = createSvgIcon(
  // credit: plus icon from https://heroicons.com/
  <svg width="93" height="37" viewBox="0 0 93 37" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M75 0C65.06 0 57 8.06 57 18V32C57 34.2 58.8 36 61 36H69V20H61V18C61 10.26 67.26 4 75 4C82.74 4 89 10.26 89 18V20H81V36H89C91.2 36 93 34.2 93 32V18C93 8.06 84.94 0 75 0ZM65 24V32H61V24H65ZM89 32H85V24H89V32Z" fill="#FF5C42" />
    <path d="M11.5833 36.0744H3.25C1.41667 35.295 0 33.931 0 30.2287V25.3573H4.41667V30.7159L5.33333 31.7876H9.5L10.4167 30.7159V21.2652L9.5 20.2422H4.95833V16.1502H9.5L10.4167 15.0785V6.3586L9.5 5.28688H5.33333L4.41667 6.3586V11.7172H0V6.84574C0 3.14344 1.41667 1.77943 3.25 1H11.5833C13.4167 1.77943 14.8333 3.14344 14.8333 6.84574V12.4966C14.8333 15.4682 14.2917 16.9784 12.6667 18.1962C14.2917 19.4628 14.8333 20.8268 14.8333 23.7984V30.2287C14.8333 33.931 13.4167 35.295 11.5833 36.0744Z" fill="white" />
    <path d="M29.9346 36.0744H21.6012C19.7679 35.295 18.3512 33.931 18.3512 30.2287V23.7984C18.3512 20.8268 18.8929 19.4628 20.5179 18.1962C18.8929 16.9784 18.3512 15.4682 18.3512 12.4966V6.84574C18.3512 3.14344 19.7679 1.77943 21.6012 1H29.9346C31.7679 1.77943 33.1846 3.14344 33.1846 6.84574V12.4966C33.1846 15.4682 32.6429 16.9784 31.0179 18.1962C32.6429 19.4628 33.1846 20.8268 33.1846 23.7984V30.2287C33.1846 33.931 31.7679 35.295 29.9346 36.0744ZM22.7679 6.3586V15.0785L23.6846 16.1502H27.8512L28.7679 15.0785V6.3586L27.8512 5.28688H23.6846L22.7679 6.3586ZM27.8512 31.7876L28.7679 30.7159V21.2652L27.8512 20.2422H23.6846L22.7679 21.2652V30.7159L23.6846 31.7876H27.8512Z" fill="white" />
    <path d="M52.2858 28.6211H48.9525V36.0744H44.5358V28.6211H35.5358V24.383L42.5775 1H46.9525L40.0775 23.9445H44.5358V19.2192L45.9525 14.9324H48.9525V23.9445H52.2858V28.6211Z" fill="white" />
  </svg>
  ,
  "Logo"
);

export default Logo;
