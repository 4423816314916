import React, { useState, ReactNode } from "react";
import CoversContext, { CoverInterface, CoverMetada } from "./CoversContext";
import * as _ from "lodash";

export class Album {
  _id: string;
  title: string;
  artist: string;
  createdAt: string;
  cover: CoverInterface;
  songs: string[];
  static createdAt: string | number | Date;
  constructor(id: string, title: string, artist: string, createdAt: string, cover: CoverInterface, songs: string[]) {
    this._id = id;
    this.title = title;
    this.artist = artist;
    this.createdAt = createdAt;
    this.cover = cover;
    this.songs = songs;
  }

  addSong(song: string) {
    try {
      this.songs.push(song);
      return song;
    } catch (error: unknown) {
      if (error instanceof Error) {
        console.error(error);
      }
    }

  }
}

export interface IAlbumsContextInterface {
  albums: Album[],
  addAlbum: (album: IAlbumMessageObject) => void
}

interface IAlbumMessageObject {
  _id: string;
  createdAt: string;
  user: { _id: string, name: string }
  artist: string;
  title: string;
  coverMetaData: CoverMetada;
  songs: { shardId: string, name: string }[];
  messageType: number;
}


const AlbumsContext = React.createContext<IAlbumsContextInterface>({
  albums: [],
  addAlbum: () => { }
});

export const AlbumsProvider = ({ children }: { children: ReactNode }) => {
  const covers = React.useContext(CoversContext)
  const [albums, setAlbums] = useState<Album[]>([]);

  const addAlbum = async (album: IAlbumMessageObject) => {
    const _cover = await covers.getCover(album.coverMetaData.imageId)
    const songs = album.songs.map((song) => {
      return song.shardId;
    });
    const _album = new Album(album._id, album.title, album.artist, album.createdAt, _cover, songs);
    setAlbums(_albums => _.uniqBy([..._albums, _album], '_id'));

  }

  return (<AlbumsContext.Provider value={{
    albums,
    addAlbum
  }}>{children} </AlbumsContext.Provider>)
};

export default AlbumsContext;

