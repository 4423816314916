import React, { useState, ReactNode } from "react";
import SBContext from '../SnackabraContext';
import WalletContext from '../WalletContext';

import * as _ from "lodash";
import { ChannelStoreType } from "react384";

interface PlayListInterface {
  _id: string,
  title: string,
  description: string,
  songs: { shardId: string, name: string }[],
}

class PlayList implements PlayListInterface {
  _id: string;
  title: string;
  description: string;
  songs: { shardId: string, name: string }[];
  constructor(id: string, title: string, description: string, songs: { shardId: string, name: string }[]) {
    this._id = id;
    this.title = title;
    this.description = description;
    console.log('songs', songs)
    this.songs = songs;
  }

  addSong(song: { shardId: string, name: string }) {
    try {
      this.songs.push(song);
      return _.uniqBy(this.songs, 'shardId');
    } catch (error: unknown) {
      if (error instanceof Error) {
        console.error(error);
      }
    }

  }

}

interface IPlayListMessageObject {
  playlist_id: string;
  title: string;
  description: string;
  songs: { shardId: string, name: string }[];
}

interface IPlayListContextInterface {
  playLists: PlayList[]
  addPlayList: (playList: IPlayListMessageObject, songs?: any[]) => void
  addSongToPlaylist: (playListId: string, song: { shardId: string, name: string }) => void
  removeSongFromPlaylist: (playListId: string, song: { shardId: string, name: string }) => void
}

const PlayListContext = React.createContext<IPlayListContextInterface>({
  playLists: [],
  addPlayList: () => { },
  addSongToPlaylist: () => { },
  removeSongFromPlaylist: () => { }
});

export const PlayListProvider = ({ children }: { children: ReactNode }) => {
  const SB = React.useContext(SBContext)
  const wallet = React.useContext(WalletContext)
  const play_list_version = 'playlist_v2';
  const [playLists, setPlayLists] = useState<PlayList[]>([]);
  const [playListSize, setPlayListSize] = useState<number>(0);

  React.useEffect(() => {
    console.log('playLists -------------adsasd', playLists)
  }, [playLists])

  const getPlayList = (playListId: string) => {
    const _playList = playLists.find((_playList) => _playList._id === playListId)
    if (_playList) {
      return _playList
    } else {
      return null
    }
  }

  const newPlayList = (playListMessage: IPlayListMessageObject, songs: { shardId: string, name: string }[]) => {
    const _playList = new PlayList(playListMessage.playlist_id, playListMessage.title, playListMessage.description, songs)
    const channel = SB?.channels[wallet?.id as string] as ChannelStoreType
    if (channel) {
      const message = {
        text: JSON.stringify({
          playlist_id: _playList._id,
          title: _playList.title,
          description: _playList.description,
          songs: _playList.songs,
          messageType: play_list_version
        })
      }
      channel.sendMessage(message)
    }

  }

  const addPlayList = (playListMessage: IPlayListMessageObject) => {
    console.log(playListMessage)
    const _playList = new PlayList(playListMessage.playlist_id, playListMessage.title, playListMessage.description, playListMessage.songs)
    setPlayLists(_playLists => _.uniqBy([..._playLists, _playList], '_id'))
    // console.log(playLists)
    // debugger
  }

  const addSongToPlaylist = (playListId: string, song: { shardId: string, name: string }) => {
    const _playList = playLists.find((_playList) => _playList._id === playListId)
    if (_playList) {
      _playList.addSong(song)
      setPlayLists(_playLists => _.uniqBy([..._playLists, _playList], '_id'))
      const channel = SB?.channels[wallet?.id as string] as ChannelStoreType
      const message = {
        text: JSON.stringify({
          playlist_id: playListId,
          title: _playList.title,
          description: _playList.description,
          songs: _playList.songs,
          messageType: play_list_version
        })
      }
      channel.sendMessage(message)
      // alert('Song added to playlist')
    }
  }

  const removeSongFromPlaylist = (playListId: string, song: { shardId: string, name: string }) => {
    const _playList = playLists.find((_playList) => _playList._id === playListId)
    if (_playList) {
      _playList.songs = _playList.songs.filter((_song) => _song.shardId !== song.shardId)
      setPlayLists(_playLists => _.uniqBy([..._playLists, _playList], '_id'))
      const channel = SB?.channels[wallet?.id as string] as ChannelStoreType
      const message = {
        text: JSON.stringify({
          playlist_id: playListId,
          title: _playList.title,
          description: _playList.description,
          songs: _playList.songs,
          messageType: play_list_version
        })
      }
      channel.sendMessage(message)
    }
  }

  return (<PlayListContext.Provider value={{
    playLists,
    addPlayList,
    addSongToPlaylist,
    removeSongFromPlaylist
  }}>{children} </PlayListContext.Provider>)
};

export default PlayListContext;

